import { useTranslation } from 'react-i18next';

import './main-nav.styles.scss';
import de from '../../assets/icons/de.svg'
import en from '../../assets/icons/en.svg'

function Mainnav() {

  const { i18n } = useTranslation();

  const handleChangeLng = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
  }

  return (
    <div className="grid main-nav" aria-label="Footer">
      <div className="cols {">
        <div className="mainnav-wrapper">
          <div className="content">
            <button onClick={() => handleChangeLng("de")}>DE<img src={de} alt="de" width="24px" height="24px" /></button>
            <span>|</span>
            <button onClick={() => handleChangeLng("en")}>EN<img src={en} alt="en" width="24px" height="24px" /></button>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Mainnav;