import './icon-link.styles.scss';

function IconLink({ icon, name, url }) {
  return (
    <a className="icon-link" href={url} target="_blank" rel="noreferrer">
      <span><i className={icon}></i>{name}</span>
    </a>
  );
};

export default IconLink;