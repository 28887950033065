import React from 'react';
import { Fragment } from 'react';

import MainNav from '../components/section-main-nav/main-nav.component';
import SectionTeaserSocials from '../components/section-teaser-socials/section-teaser-socials.components';
import IconBar from "../components/section-icon-bar/icon-bar.component";
import Footer from '../components/section-footer/footer.component';


import '../index.scss';

const App = () => {

  return (
    <Fragment>
      <MainNav></MainNav>
      <SectionTeaserSocials />
      <IconBar />
      <Footer />
    </Fragment>
  )
}

export default App;