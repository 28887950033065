import { useTranslation } from 'react-i18next';
import './icon-link.styles.scss';


function IconLink({ icon, name, url }) {
  
  const { t } = useTranslation();

  return (
    <a className="icon-link external" href={url} target="_blank" rel="noreferrer">
      <span>{t(name)}<i className={icon}></i></span>
    </a>
  );
};

export default IconLink;