import React, { useLayoutEffect, useRef } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import IconLink from "../comp-icon-link/icon-link-external.component";
import { useTranslation } from 'react-i18next';

import './section-teaser-socials.styles.scss';

gsap.registerPlugin(ScrollTrigger);

function SectionTeaserSocials() {

  const { t } = useTranslation();

  const comp = useRef(); // create a ref for the root level element (for scoping)
  const cardRefs = useRef([]); // array to store refs for the card elements

  const addToCardRefs = (el) => {
    if (el && !cardRefs.current.includes(el)) {
      cardRefs.current.push(el); // add each card element to the refs array
    }
  };

  useLayoutEffect(() => {
    // create context and match media rules
    let mm = gsap.matchMedia();

    mm.add("(min-width: 992px)", () => {
      // GSAP animation for desktop
      cardRefs.current.forEach((card, index) => {
        gsap.from(card, {
          duration: 0.6,
          opacity: 0,
          y: 50,
          delay: 0.2 * index, // stagger animation by 0.2s per card
          scrollTrigger: {
            trigger: card,  // Trigger animation when the individual element enters the viewport
            start: 'top 80%',   // Start when the top of the element is 80% in the viewport
            toggleActions: 'play none none none', // Play the animation once when in view
          },
        });
      });
    }, comp); // Scopes selector text

    return () => mm.revert(); // cleanup function to revert GSAP when component unmounts
  }, []);

  return (
    <section className="grid section-teaser-socials comp-spacing" aria-label="Referenzen" ref={comp}>
      <div className="content">
        <h1 className="headline">{t("Alle meine Links")}</h1>
        <div className="card-wrapper">
          <div className="card twitch" key={0} ref={addToCardRefs}>
            <IconLink
              class="icon-link mb-30"
              icon="fa-brands fa-twitch"
              url="https://www.twitch.com/GlosNerd"
            />
          </div>
          <div className="card youtube" key={1} ref={addToCardRefs}>
            <IconLink
              class="icon-link mb-30"
              icon="fa-brands fa-youtube"
              url="https://www.youtube.com/channel/UC68esulqCb0yh9ftuukUzbw"
            />
          </div>
          <div className="card instagram" key={2} ref={addToCardRefs}>
            <IconLink
              class="icon-link mb-30"
              icon="fa-brands fa-instagram"
              url="https://www.instagram.com/GlosNerd"
            />
          </div>
          <div className="card x-twitter" key={3} ref={addToCardRefs}>
            <IconLink
              class="icon-link mb-30"
              icon="fa-brands fa-square-x-twitter"
              url="https://twitter.com/GlosNerd"
            />
          </div>
          <div className="card facebook" key={4} ref={addToCardRefs}>
            <IconLink
              class="icon-link mb-30"
              icon="fa-brands fa-facebook-square"
              url="https://www.facebook.com/GlosNerd"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default SectionTeaserSocials;
